import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class StateService {


      #api;
      constructor(){
            this.#api = `${API_URL}user/country`;
      }
      all(country){
            let url  = `${this.#api}/${country}/states`;
            return apiService.get(url);
      }

      paginate(countryId, page = 1) {
            let url = `${this.#api}/${countryId}/state`;
            if (page)
                  url = url + '?page=' + page;
            return apiService.get(url);
      }

      update(countryId, id, data) {
            let url = `${this.#api}/${countryId}/state/${id}`
            return apiService.put(url, data);

      }

      store(countryId, data) {
            let url = `${this.#api}/${countryId}/state`
            return apiService.post(url, data);

      }

      show(countryId, id) {
            let url = `${this.#api}/${countryId}/state/${id}`
            return apiService.get(url);
      }

      delete(countryId, id) {
            let url = `${this.#api}/${countryId}/state/${id}`
            return apiService.delete(url);
      }
}
