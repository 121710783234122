<template>
    <div>
        <b-modal id="modal-scrollable" size="md" ref="my-modal" hide-footer title="Add/Edit Address">
            <div class="row">
                <div class="col-12 form-group">
                    <input type="text" class="form-control" v-model="state.title" placeholder="Title"/>
                    <span class="text-danger" v-if="$v.state.title.$error">Title is required</span>
                </div>
                <div class="col-6 form-group">
                    <input type="text" class="form-control" v-model="state.state_code" placeholder="Prefix"/>
                    <!-- <span class="text-danger" v-if="$v.state.title.$error">Title is required</span> -->
                </div>
                <div class="col-6 form-group">
                    <label for="">Is Active</label>
                    <b-form-checkbox
                            v-model="state.is_active"
                            name="check-button"
                            class
                            switch
                    >Status
                    </b-form-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col-6">

                    <v-btn
                            class="text-primary"
                            depressed
                            block
                            @click="resetForm"
                    >Cancel
                    </v-btn>
                </div>
                <div class="col-6">
                    <v-btn
                            class="text-primary btn btn-primary"
                            depressed
                            block
                            @click="createOrUpdate"
                            :loading="isBusy"
                    >Save
                    </v-btn>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
    import {required} from "vuelidate/lib/validators";

    import StateService from "@/core/services/address/StateService";

    const stateService = new StateService();

    export default {
        props: ["country_id"],
        validations: {
            state: {
                title: {required}
            }
        },
        data() {
            return {
                edit: false,
                isBusy: false,
                state: {
                    title: null
                }
            };
        },
        methods: {
            showModal(id = null) {
                if (id) {
                    this.edit = true;
                    this.getState(id);
                }
                this.$refs["my-modal"].show();
            },
            hideModal() {
                this.$refs["my-modal"].hide();
            },
            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            },
            getState(id) {
                stateService.show(this.country_id, id).then(response => {
                    this.state = response.data.state;
                });
            },

            createOrUpdate() {
                this.$v.state.$touch();
                if (this.$v.state.$error) {
                    setTimeout(() => {
                        this.$v.state.$reset();
                    }, 3000);
                } else {
                    if (this.edit) {
                        this.updateState();
                    } else {
                        this.createState();
                    }
                }
            },
            updateState() {
                this.isBusy = true;
                stateService
                    .update(this.country_id, this.state.id, this.state)
                    .then(response => {
                        this.resetForm();
                        this.isBusy = false;
                    })
                    .catch(error => {
                        this.isBusy = false;
                        // console.log(error);
                    });
            },
            createState() {
                this.isBusy = true;
                stateService
                    .store(this.country_id, this.state)
                    .then(response => {
                        this.resetForm();
                        this.isBusy = false;
                    })
                    .catch(error => {
                        this.isBusy = false;
                        // console.log(error);
                    });
            },
            resetForm() {
                this.edit = false;
                this.state = {
                    title: null
                }
                this.$v.state.$reset();
                this.hideModal();
                this.$emit("refresh_state");
            }
        }
    };
</script>

