<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
          class="wizard wizard-2"
          id="kt_wizard_v2"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <!--begin: Wizard Form-->

            <div class="card">
              <div class="card-body">
                <div class="card-title">
                      <div class="row">
                  <div class="col-6">
                                          <h4>States</h4>
                  </div>
                  <div class="col-6 text-right">
                        <button class="btn btn-primary" @click="addState">Add state</button>
                  </div>
                </div>

                  <div class="col-12">
                    <div class="">
                    <table class="table">
                      <thead>
                        <th>Name</th>
                        <th>State Code</th>
                        <th>Status</th>
                        <th>Action</th>
                      </thead>
                      <tbody>
                        <tr v-for="(state, index) of states" :key="index">
                          <td>{{state.title}}</td>
                          <td>{{state.state_code}}</td>
                          <td>
                             <i v-if="state.is_active" class="fas fa-check text-success"></i>
                        <i v-else class="fas fa-ban text-danger"></i>
                          </td>
                          <td>
                            <span class="cursor-pointer" @click="editState(state)">
                              <i class="fas fa-edit"></i>
                            </span>
                            <span class="cursor-pointer" @click="deleteState(state.id)">
                              <i class="ml-3 fas fa-trash"></i>
                            </span> <span class="cursor-pointer" @click="cityDetail(state.id)">
                              <i class="ml-3 fas fa-eye"></i>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      aria-controls="my-table"
                      @change="getStates"
                    ></b-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-and-update :country_id="country_id" ref="state" @refresh_state="getStates"></create-and-update>
  </v-app>
</template>
<script>
import StateService from "@/core/services/address/StateService";
import CreateAndUpdate from "./CreateAndUpdate";

const stateService = new StateService();

export default {
      components:{
            CreateAndUpdate
      },
  data() {
    return {
      states: [],
      perPage: 25,
      currentPage: 1,
      rows: 0
    };
  },computed:{
    country_id(){
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getStates();
  },
  methods: {
    getStates() {
      stateService.paginate(this.country_id, this.currentPage).then(response => {
        this.states = response.data.data;
        //   this.currentPage = response.data.meta.current_page
        this.perPage = response.data.meta.per_page;
        this.rows =
          parseInt(response.data.meta.last_page) * parseInt(this.perPage);
      });
    },
    deleteState(id) {
      stateService.delete(this.country_id,id).then(response => {
        this.getStates();
      });
    }, editState(state){
          this.$refs['state'].showModal(state.id)
    },addState(){
          this.$refs['state'].showModal()
    },cityDetail(id){
          this.$router.push({name:"city-list", params:{id:id}})
    }
  }
};
</script>
